<template>
  <v-container fluid>
    <v-row>
      <v-col class="pt-3 pb-2">
        <h1>Add New Daily Return Sheet</h1>
        <p class="pt-2">
          <v-btn small @click="showChangeJobNo = true">Select Job Number</v-btn>
        </p>
      </v-col>
    </v-row>
    <div v-if="drs.job_no.length > 0">
      <v-row>
        <v-col class="col-3 pt-3 pb-2">
          <v-text-field dense label="Customer" v-model="drs.quotation.customer" disabled></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-3 pt-3 pb-2">
          <label>Date for the DRS</label>
          <v-menu
            v-model="menu"
            :close-on-content-click="false"
            :nudge-right="40"
            transition="scale-transition"
            offset-y
            min-width="auto"
          >
            <template v-slot:activator="{ on, attrs }">
              <v-text-field
                dense
                v-model="drs.work_date"
                prepend-icon="mdi-calendar"
                readonly
                v-bind="attrs"
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="date"
              @input="menu = false"
            ></v-date-picker>
          </v-menu>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-3 pt-3 pb-2">
          <v-select
            label="Department"
            v-model="drs.department_id"
            :items="departments"></v-select>
        </v-col>
      </v-row>
      <v-row>
      <v-col class="col-3 pt-3 pb-2">
        <v-select
          label="Surface Type"
          v-model="drs.surface_type_id"
          :items="surfaceTypes"></v-select>
      </v-col>
    </v-row>
      <v-row>
        <v-col class="col-3 pt-3 pb-2">
          <v-select
            label="Surface Conditions"
            v-model="drs.surface_condition_id"
            :items="surfaceConditions"></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-3 pt-3 pb-2">
          <v-select
            label="Weather Conditions"
            v-model="drs.weather_id"
            :items="weatherConditions"></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-6 pt-3 pb-2">
          <v-text-field dense disabled v-model="drs.quotation.site" label="Site"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-3 pt-3 pb-2">
          <v-select
            label="Chargehand"
            v-model="drs.daily_return_sheets_workers[0].crew_member.id"
            :items="crewMembers"
            item-value="id"
            item-text="name"></v-select>
        </v-col>
      </v-row>
      <div v-for="(worker, index) in drs.daily_return_sheets_workers" :key="`w_${index}`">
        <v-row v-if="index > 0">
          <v-col class="col-3 pt-3 pb-2">
            <v-select
              :label="`Worker ${index}`"
              v-model="worker.crew_member.id"
              :items="crewMembers"
              item-value="id"
              item-text="name"></v-select>
          </v-col>
        </v-row>
      </div>
      <v-row>
        <v-col class="col-3 pt-3 pb-2">
          <v-text-field
            v-model="drs.vehicle"
            label="Vehicle Reg"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-3 pt-3 pb-2">
          <v-text-field
            v-model="drs.vehicle_two"
            label="2nd Vehicle Reg"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-3 pt-3 pb-2">
          <v-text-field
            v-model="drs.vehicle_three"
            label="3rd Vehicle Reg"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-3 pt-3 pb-2">
          <v-text-field
            v-model="drs.drawing_number"
            label="Drawing No"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-3 pt-3 pb-2">
          <v-text-field
            v-model="drs.revision_number"
            label="Revision No"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-3 pt-3 pb-2">
          <v-select
            label="Type of Works"
            v-model="drs.job_type_id"
            :items="jobTypes"></v-select>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-9 pt-3 pb-2">
          <p>
            Please use the selection tickboxes to detail the material/job type. You can select
            more than one item.
          </p>
          <v-checkbox dense ripple="false" label="Overbanding Grout" v-model="drs.overbanding_grout"></v-checkbox>
          <v-checkbox dense ripple="false" label="Beads" v-model="drs.beads"></v-checkbox>
          <v-checkbox dense ripple="false" label="Epoxy" v-model="drs.epoxy"></v-checkbox>
          <v-checkbox dense ripple="false" label="Thermoplastic" v-model="drs.thermoplastic"></v-checkbox>
          <v-checkbox dense ripple="false" label="Paint" v-model="drs.paint"></v-checkbox>
          <v-checkbox dense ripple="false" label="MMA" v-model="drs.mma"></v-checkbox>
          <v-checkbox dense ripple="false" label="Anti Skid" v-model="drs.anti_skid"></v-checkbox>
          <v-checkbox dense ripple="false" label="Tack Coat" v-model="drs.tack_coat"></v-checkbox>
          <v-checkbox dense ripple="false" label="Remedial Work" v-model="drs.remedial_work"></v-checkbox>
          <v-checkbox dense ripple="false" label="Sealer" v-model="drs.sealer"></v-checkbox>
        </v-col>
      </v-row>
      <v-row>
      <v-col class="col-9 pt-3 pb-2">
        <hr />
        <p class="pt-4">Health and Safety</p>
        <v-checkbox
          dense
          ripple="false"
          label="Tick to confirm all PPE was available and in good condition"
          v-model="drs.ppe_good_condition"></v-checkbox>
        <hr />
      </v-col>
    </v-row>
      <v-row>
        <v-col class="col-3">
          <v-text-field v-model="drs.mileage" label="Mileage"></v-text-field>
        </v-col>
      </v-row>
      <v-row>
        <v-col class="col-3">
          <v-text-field v-model="drs.notes" label="Notes"></v-text-field>
        </v-col>
      </v-row>
      <v-row class="pb-10">
        <v-col>
          <v-btn x-large @click="save">Save</v-btn>
        </v-col>
      </v-row>
    </div>
    <v-dialog
      v-model="showChangeJobNo"
      max-width="600"
    >
      <v-card>
        <v-card-text class="pt-4">
          <label v-if="(jobNumbers.length === 0)">
            Enter the job number you wish to use for this DRS below:
          </label>
          <span v-if="(confirmNumber === false)">
            <v-checkbox
              v-for="(number, index) in jobNumbers"
              :key="index"
              :label="number"
              :ripple="false"
              @change="confirmJobNumber(index)"></v-checkbox>
          </span>
          <span v-else>
            Confirm the job number for this DRS: {{ selectedJobNumber }}
            <v-btn @click="selectJobNumber" color="green darken-2" text>Confirm</v-btn>
          </span>
        </v-card-text>
        <v-card-actions>
          <v-text-field
            v-if="(jobNumbers.length === 0)"
            placeholder="Job number"
            v-model="jobNumberTerm"></v-text-field>
          <v-btn
            v-if="(jobNumbers.length === 0)"
            color="orange darken-2"
            text
            @click="findJobNumbers"
          >
            Search
          </v-btn>
          <v-btn
            color="orange darken-2"
            text
            @click="showChangeJobNo = false"
          >
            Cancel
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>

<script>
import axios from '../../axios';

export default {
  name: 'DrsCreate',
  computed: {
    token() {
      return this.$store.state.token;
    },
  },
  watch: {
    date () {
      this.drs.work_date = this.formatDate(this.date);
    },
    totalBonus(value) {
      this.individualBonus = (value / this.drs.number_in_gang);
    },
    drs: {
      handler() {
        let totalValue = 0;
        for (let i = 0; this.drs.daily_return_sheets_bonus_payments.length > i; i += 1) {
          const lineValue = (
            this.drs.daily_return_sheets_bonus_payments[i].quantity * 
            this.drs.daily_return_sheets_bonus_payments[i].rate
          );
          totalValue += lineValue;
        }
        this.totalBonus = totalValue.toFixed(2);
      },
      deep: true,
    },
  },
  data() {
    return {
      drs: {
        id: 0,
        quotation_id: 0,
        job_no: '',daily_return_sheets_bonus_payments: [],
        daily_returns_sheets_material_usages: [],
        daily_return_sheets_workers: [
          { crew_member: {} },
          { crew_member: {} },
          { crew_member: {} },
          { crew_member: {} },
          { crew_member: {} },
          { crew_member: {} },
          { crew_member: {} },
          { crew_member: {} },
          { crew_member: {} },
        ],
        quotation: {
          customer: {},
        },
      },
      showChangeJobNo: false,
      jobNumberTerm: '',
      jobNumbers: [],
      selectedJobNumber: '',
      confirmNumber: false,
      surfaceConditions: [],
      surfaceTypes: [],
      weatherConditions: [],
      workTypes: [],
      jobTypes: [],
      departments: [
        { 'value':1, 'text':'Paint'},
        { 'value':2, 'text':'Thermo'},
      ],
      crewMembers: [],
      times: [],
      menu: false,
      date: null,
      boolean: [
        { text: "No", value: 1 },
        { text: "Yes", value: 0 },
      ],
    };
  },
  methods: {
    formatDate (date) {
      if (!date) return null;
      const [year, month, day] = date.split('-');
      return `${day}-${month}-${year}`;
    },
    getSurfaceConditions() {
      axios.get(`/surfaceConditions/getList.json?token=${this.token}`)
        .then((response) => {
          this.surfaceConditions = response.data.surfaceConditions;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getSurfaceTypes() {
      axios.get(`/surfaceTypes/getList.json?token=${this.token}`)
        .then((response) => {
          this.surfaceTypes = response.data.surfaceTypes;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getWeatherConditions() {
      axios.get(`/weatherConditions/getList.json?token=${this.token}`)
        .then((response) => {
          this.weatherConditions = response.data.weatherConditions;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    getWorkTypes() {
      axios.get(`/workTypes/getList.json?token=${this.token}`)
        .then((response) => {
          this.workTypes = response.data.workTypes;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    save() {
      const postData = {};
      postData.drs = this.drs;
      axios.post(`/dailyReturnSheets/create.json?token=${this.token}`, postData)
        .then(() => {
          this.$router.push(`/drs/update/${this.drs.id}`);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    selectJobNumber() {
      const postData = {};
      postData.newJobNumber = this.selectedJobNumber;
      postData.drs = this.drs.id;
      axios.post(`/dailyReturnSheets/selectJobNumber.json?token=${this.token}`, postData)
        .then((response) => {
          this.drs.id = response.data.dailyReturnSheet.id;
          this.drs.quotation_id = response.data.dailyReturnSheet.quotation_id;
          this.drs.job_no = response.data.dailyReturnSheet.job_no;
          this.drs.quotation = response.data.quotation;
          this.showChangeJobNo = false;
          this.jobNumberTerm = '';
          this.jobNumbers = [];
          this.selectedJobNumber = '';
          this.confirmNumber = false;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    confirmJobNumber(index) {
      this.selectedJobNumber = this.jobNumbers[index];
      this.confirmNumber = true;
    },
    findJobNumbers() {
      const postData = {};
      postData.searchTerm = this.jobNumberTerm;
      axios.post(`/quotations/searchJobNumbers.json?token=${this.token}`, postData)
        .then((response) => {
          this.jobNumbers = response.data.jobNumbers;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    generateTimes() {
      let i = 0; 
      const times = [];
      let time = 0.25;
      while(i < 71) {
        times.push(time);
        time += 0.25;
        i += 1;
      }
      this.times = times;
    },
    getCrewMembers() {
      axios.get(`/crewMembers/getCrewForDrsEdit.json?token=${this.token}`)
        .then((response) => {
          this.crewMembers = response.data.crewMembers;
        });
    },
    getJobTypes() {
      axios.get(`/jobTypes/getList.json?token=${this.token}`)
        .then((response) => {
          this.jobTypes = response.data.jobTypes;
        });
    },
    getBonuses() {
      axios.get(`/bonusRates/getBonuses.json?token=${this.token}`)
        .then((response) => {
          this.bonuses = response.data.bonuses;
        });
    },
  },
  mounted() {
    this.getSurfaceConditions();
    this.getSurfaceTypes();
    this.getWeatherConditions();
    this.getWorkTypes();
    this.generateTimes();
    this.getCrewMembers();
    this.getJobTypes();
    this.getBonuses();
  },
};
</script>
